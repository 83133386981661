import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ppdynamic.css";
import ResponsiveDrawer from "../drawer";

const Playersprofiledynamic = () => {
  const [names, setNames] = useState("");
  const [role, setRole] = useState("");
  const [game_played, setGamePlayed] = useState("");
  const [catch_taken, setCatchTaken] = useState("");
  const [total_awards, setTotalAwards] = useState("");
  const [wickets_taken, setWicketsTaken] = useState("");
  const [run_score, setRunScore] = useState("");
  const [best_figure, setBestFigure] = useState("");
  const [skills, setSkills] = useState("");
  const [rank, setRank] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [images, setImages] = useState([]);
  const [editingImage, setEditingImage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState("");

  // Fetch images from backend when component mounts
  useEffect(() => {
    fetchImages();
  }, []);

  // Function to fetch images
  const fetchImages = async () => {
    try {
      const response = await axios.get("https://backendroyal.nepalmodelsecondaryschool.com/api/players");
      setImages(response.data.reverse());
    } catch (error) {
      console.error("Error fetching images:", error);
      setError("Failed to fetch images.");
    }
  };

  // Function to handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!names || !role || !game_played || !catch_taken || !total_awards || !wickets_taken || !run_score || !best_figure || !skills || !description || (!image && !editingImage) || !rank) {
      setError("All fields are required.");
      return;
    }

    const formData = new FormData();
    formData.append("names", names);
    formData.append("role", role);
    formData.append("game_played", game_played);
    formData.append("catch_taken", catch_taken);
    formData.append("total_awards", total_awards);
    formData.append("wickets_taken", wickets_taken);
    formData.append("run_score", run_score);
    formData.append("best_figure", best_figure);
    formData.append("skills", skills);
    formData.append("rank", rank);
    formData.append("description", description);
    if (image) {
      formData.append("image", image);
    }

    try {
      let response;
      if (editingImage) {
        response = await axios.put(
          `https://backendroyal.nepalmodelsecondaryschool.com/api/players/${editingImage._id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            },
          }
        );
        setEditingImage(null);
      } else {
        response = await axios.post(
          "https://backendroyal.nepalmodelsecondaryschool.com/api/players",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            },
          }
        );
      }
      console.log(response.data);
      fetchImages();
      setUploadProgress(0);
      resetForm();
    } catch (error) {
      console.error("Error uploading/updating image:", error);
      setError("Failed to upload or update image.");
    }
  };

  // Function to reset the form
  const resetForm = () => {
    setNames("");
    setRole("");
    setGamePlayed("");
    setCatchTaken("");
    setTotalAwards("");
    setWicketsTaken("");
    setRunScore("");
    setBestFigure("");
    setRank("");
    setSkills("");
    setDescription("");
    setImage(null);
    setImagePreview(null);
  };

  // Function to handle editing an image
  const handleEdit = (image) => {
    setNames(image.names);
    setRole(image.role);
    setGamePlayed(image.game_played);
    setCatchTaken(image.catch_taken);
    setTotalAwards(image.total_awards);
    setWicketsTaken(image.wickets_taken);
    setRunScore(image.run_score);
    setBestFigure(image.best_figure);
    setSkills(image.skills);
    setRank(image.rank);
    setDescription(image.description);
    setEditingImage(image);
    setImage(null); // Clear the image file input
    setImagePreview(null); // Clear the image preview
  };

  // Function to handle deleting an image
  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://backendroyal.nepalmodelsecondaryschool.com/api/players/${id}`);
      fetchImages();
    } catch (error) {
      console.error("Error deleting image:", error);
      setError("Failed to delete image.");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="fo">
        <h2>Enter Player Details</h2>
        <input
          type="text"
          value={names}
          onChange={(e) => setNames(e.target.value)}
          placeholder="Name"
          required
        />
        <input
          type="text"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          placeholder="Role"
          required
        />
        <input
          type="text"
          value={game_played}
          onChange={(e) => setGamePlayed(e.target.value)}
          placeholder="Total games played"
          required
        />
        <input
          type="text"
          value={catch_taken}
          onChange={(e) => setCatchTaken(e.target.value)}
          placeholder="Total catches taken"
          required
        />
        <input
          type="text"
          value={total_awards}
          onChange={(e) => setTotalAwards(e.target.value)}
          placeholder="Total awards"
          required
        />
        <input
          type="text"
          value={wickets_taken}
          onChange={(e) => setWicketsTaken(e.target.value)}
          placeholder="Total wickets taken"
          required
        />
        <input
          type="text"
          value={run_score}
          onChange={(e) => setRunScore(e.target.value)}
          placeholder="Total run score"
          required
        />
        <input
          type="text"
          value={best_figure}
          onChange={(e) => setBestFigure(e.target.value)}
          placeholder="Best figure"
          required
        />
        <input
          type="text"
          value={skills}
          onChange={(e) => setSkills(e.target.value)}
          placeholder="Skills"
          required
        />
        <input
          type="text"
          value={rank}
          onChange={(e) => setRank(e.target.value)}
          placeholder="Ranking"
          required
        />
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Comments from coach"
          required
        ></textarea>
        <input
          type="file"
          onChange={handleFileChange}
          accept="image/*"
          required={!editingImage}
        />
        {imagePreview && <img src={imagePreview} alt="Preview" className="image-preview" />}
        <button type="submit">
          {editingImage ? "Update Player" : "Add Player"}
        </button>
        {error && <div className="error-message">{error}</div>}
      </form>

      {uploadProgress > 0 && (
        <div className="progress-bar">
          <div
            className="progress"
            style={{ width: `${uploadProgress}%` }}
          ></div>
        </div>
      )}

      <div className="getting">
        {images.map((image) => (
          <div key={image._id} className="image-card">
            <h3>Name: {image.names}</h3>
            <p>Role: {image.role}</p>
            <p>Games Played: {image.game_played}</p>
            <p>Catches Taken: {image.catch_taken}</p>
            <p>Total Awards: {image.total_awards}</p>
            <p>Wickets Taken: {image.wickets_taken}</p>
            <p>Run Score: {image.run_score}</p>
            <p>Ranking: {image.rank}</p>
            <p>Best Figure: {image.best_figure}</p>
            <p>Skills: {image.skills}</p>
            <p>Comments: {image.description}</p>
            <img
              src={`https://backendroyal.nepalmodelsecondaryschool.com/${image.imageUrl}`}
              alt={image.names}
              className="image-thumbnail"
            />
            <button onClick={() => handleEdit(image)}>Edit</button>
            <button onClick={() => handleDelete(image._id)}>Delete</button>
          </div>
        ))}
      </div>
      <ResponsiveDrawer />
    </div>
  );
};

export default Playersprofiledynamic;

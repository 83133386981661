import React from 'react'
import Ourteams from '../teams/ourteams'
import Footers from '../footer/footers'

export default function Team() {
  return (
    <div>
      <Ourteams/>
      <Footers/>
    </div>
  )
}

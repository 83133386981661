import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ResponsiveDrawer from '../admin/dashboard/drawer';

const Newad = () => {
  const [admissions, setAdmissions] = useState([]);

  const fetchAdmissions = async () => {
    try {
      const response = await axios.get('https://backendroyal.nepalmodelsecondaryschool.com/admissions');
      setAdmissions(response.data);
    } catch (error) {
      console.error('Error fetching admissions', error);
    }
  };

  const deleteAdmission = async (id) => {
    try {
      await axios.delete(`https://backendroyal.nepalmodelsecondaryschool.com/admission/${id}`);
      fetchAdmissions(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting admission', error);
    }
  };

  const printImage = (filePath) => {
    const newWindow = window.open('');
    newWindow.document.write(`<img src="${filePath}" onload="window.print(); window.close();" />`);
    newWindow.document.close();
  };

  const shareOnSocialMedia = (platform, admission) => {
    const url = `https://backendroyal.nepalmodelsecondaryschool.com${admission.filePath}`;
    const text = `Check out this admission: ${admission.name}, Contact: ${admission.contact}`;
    let shareUrl = '';

    switch (platform) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(text)}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;
        break;
      default:
        return;
    }

    window.open(shareUrl, '_blank');
  };

  useEffect(() => {
    fetchAdmissions();
  }, []);

  return (
    <>
      <div className="events-container">
        {admissions.map((admission) => (
          <div className="event-card" key={admission._id}>
            <div className="event-card__img">
              <img
                src={`https://backendroyal.nepalmodelsecondaryschool.com${admission.filePath}`}
                alt={admission.name}
              />
            </div>
            <div className="event-card__content">
              <h2>{admission.name}</h2>
              <p>{`Contact: ${admission.contact}`}</p>
              <p>{`Permanent Address: ${admission.permanentAddress}`}</p>
              <p>{`Current Address: ${admission.currentAddress}`}</p>
              <p>{`Previous Club: ${admission.previousClub}`}</p>
              <p>{`DOB: ${admission.dob}`}</p>
              <p>{`Interest: ${admission.interest}`}</p>
              <p>{`How did you hear about us: ${admission.aboutUs}`}</p>
              <p>{`Gender: ${admission.gender}`}</p>
              <button onClick={() => deleteAdmission(admission._id)}>Delete</button>
              <button onClick={() => printImage(`https://backendroyal.nepalmodelsecondaryschool.com${admission.filePath}`)}>Print Image</button>
              <div className="social-share-buttons">
                <button onClick={() => shareOnSocialMedia('facebook', admission)}>Share on Facebook</button>
                <button onClick={() => shareOnSocialMedia('twitter', admission)}>Share on Twitter</button>
                <button onClick={() => shareOnSocialMedia('linkedin', admission)}>Share on LinkedIn</button>
              </div>
            </div>
          </div>
        ))}
      </div>21/65qwA Q 
      <ResponsiveDrawer />
    </>
  );
};

export default Newad;

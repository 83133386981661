import React from "react";
import "./international.css";
import { Helmet } from "react-helmet";

export default function International() {
  return (
    <>
      <Helmet>
        <title>International Players - Royal Cricket Academy</title>
        <meta
          name="description"
          content="Meet Kishore Mahato, a national player from Royal Cricket Academy. Discover his journey and message from our academy. Join us to excel in cricket."
        />  
        <meta
          name="keywords"
          content="international players, Royal Cricket Academy, national cricket players, cricket training, cricket achievements"
        />
        <meta property="og:title" content="International Players - Royal Cricket Academy" />
        <meta
          property="og:description"
          content="Learn about the accomplishments and messages from our top national players at Royal Cricket Academy. Join us to develop your cricket skills with guidance from experienced professionals."
        />
        <meta
          property="og:image"
          content="https://www.cricnepal.com/wp-content/uploads/2023/06/Kishore-Mahato-768x512.jpg"
        />
        <meta property="og:url" content="https://www.royalcricketacademy.com/international-players" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="flex-box-pegination">
        <div className="items-image-box">
          <img
            src="https://www.cricnepal.com/wp-content/uploads/2023/06/Kishore-Mahato-768x512.jpg"
            alt="Kishore Mahato, National Player from RCA"
            className="national-players-image"
          />
        </div>
        <div className="items-image-box">
          <div className="personal-details">
            <h3 className="">Kishore Mahato</h3>
            <p>National Player from RCA</p>
          </div>

          <div className="user-details">
            <p className="animation">
              <h1 className="mna">Message from National Player</h1>
              <br />
              Welcome to Royal Cricket Academy! We believe that everyone can
              learn and improve at cricket. We don't care about your age,
              gender, or skill level. We just want you to have fun and enjoy the
              game. We are committed to providing you with a positive and
              supportive environment. We want you to feel comfortable asking
              questions and learning from your coaches and teammates.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

import React from 'react'
import Fixednavbar from '../fixedbackground/fixednavbar'
import Footers from '../footer/footers'

export default function Contacts() {
  return (
    <div>
      <Fixednavbar/>
      <Footers/>
    </div>
  )
}

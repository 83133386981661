import React from "react";
import "./about.css"; // Import your CSS file for styling
import { Helmet } from "react-helmet"; // Import Helmet for meta tags
const AboutUsComponent = () => {
  return (
    <div>
      <Helmet>
        <title>
          About Us - Royal Cricket Academy | Leading Cricket Training in Nepal
        </title>
        <meta
          name="description"
          content="Learn about the Royal Cricket Academy, a top cricket training center in Nepal. Discover our vision, values, and commitment to developing cricket talent at all levels."
        />
        <meta
          name="keywords"
          content="Royal Cricket Academy, cricket training Nepal, cricket coaching Kathmandu, cricket academy values, cricket vision"
        />
      </Helmet>
      <div className="hello" data-aos="fade-up">
        <div className="massage-box">
          <div className="kela">
            <h2 className="bye">Who We Are</h2>
          </div>
          <div className="row_pregination">
            <div className="ccol">
              <div className="hello_dhakal">
                <h2 className="our">OUR VISION</h2>
                <br />
                <br />
                <p className="PARA">
                  To provide an environment for participation, learning, and
                  success that values strong leadership and fosters youth,
                  sportsmanship, excellence, and professionalism on the field
                  and in the community.
                </p>
                <br />
                <br />
                <br />
              </div>
              <div className="royal">
                <h2 className="sa">ROYAL CRICKET ACADEMY</h2>
                <br />
                <br />
                <p className="classes">
                  A Royal cricket academy is one of the leading cricket
                  academies in Nepal. We are dedicated to helping aspiring
                  cricketers of all ages and skill levels reach their full
                  potential. Our coaches have years of experience in every
                  aspect of the game, and our classes are designed to help you
                  develop the skills needed for success. Whether you’re looking
                  for a competitive edge or just want to stay active, we have
                  something for everyone.
                </p>
                <br />
                <br />
              </div>
            </div>
            <div className="ccol">
              <div className="the-most">
                <h2 className="values">VALUES</h2>
                <br />
                <br />
                <p className="val">
                  • Integrity • Diversity • Passion for Cricket • Respect for
                  <br /> People and Community • Enjoyment • Discipline • Healthy
                  Lifestyle • Go Green
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsComponent;

import React, { useState } from "react";
import axios from "axios";
import "./newad.css";
import { Helmet } from "react-helmet";
import Footers from "../footer/footers";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const Newadmission = () => {
    const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    permanentAddress: "",
    currentAddress: "",
    previousClub: "",
    dob: "",
    interest: "",
    aboutUs: "",
    gender: "",
    file: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      file: e.target.files[0]
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    axios.post("https://backendroyal.nepalmodelsecondaryschool.com/submit", data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then((response) => {
      console.log(response.data);
      
      navigate("/");
      toast.success("Form submitted successfully")
    })
    .catch((error) => {
      console.error("There was an error submitting the form!", error);
      toast.error("Login failed");
    });
  };

  return (
    <div>
       <Helmet>
        <title>Admission Form | Royal Cricket Academy</title>
        <meta name="description" content="Apply to join the Royal Cricket Academy with our comprehensive admission form. Provide details like name, contact information, addresses, and upload necessary documents." />
        <meta name="keywords" content="cricket academy admission, apply to cricket academy, Royal Cricket Academy form, cricket training application, cricket club admission form" />
        <meta property="og:title" content="Royal Cricket Academy Admission Form" />
        <meta property="og:description" content="Submit your application to join the Royal Cricket Academy. Complete our admission form with personal details and necessary documents to get started." />
        <meta property="og:url" content="https://yourwebsite.com/newadmission" />
        <meta property="og:image" content="https://images.sampletemplates.com/wp-content/uploads/2023/06/Sample-Institute-Admission-Form-Template.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Royal Cricket Academy Admission Form" />
        <meta name="twitter:description" content="Apply now to join the Royal Cricket Academy. Fill out our admission form with your details and documents." />
        <meta name="twitter:image" content="https://images.sampletemplates.com/wp-content/uploads/2023/06/Sample-Institute-Admission-Form-Template.jpg" />
      </Helmet>
      <div className="center">
        <div className="signUp">
          <div className="left">
            <div className="back"></div>
          </div>
          <div className="right">
            <form onSubmit={handleSubmit}>
              <div className="field">
                <h1 className="join">Join yourself in RCA team</h1>
                <div className="input-bordered">
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="field">
                <div className="input-bordered">
                  <input
                    type="text"
                    name="contact"
                    placeholder="Enter your contact"
                    value={formData.contact}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="field">
                <div className="input-bordered">
                  <input
                    type="text"
                    name="permanentAddress"
                    placeholder="Permanent Address"
                    value={formData.permanentAddress}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="field">
                <div className="input-bordered">
                  <input
                    type="text"
                    name="currentAddress"
                    placeholder="Current Address"
                    value={formData.currentAddress}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="field">
                <div className="input-bordered">
                  <input
                    type="text"
                    name="previousClub"
                    placeholder="Previous played Club or Coaching Center"
                    value={formData.previousClub}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="field">
                <div className="input-bordered">
                  <input
                    type="text"
                    name="dob"
                    placeholder="Enter Correct DOB"
                    value={formData.dob}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="field">
                <div className="input-bordered">
                  <input
                    type="text"
                    name="interest"
                    placeholder="Playing Interest"
                    value={formData.interest}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="field">
                <div className="input-bordered">
                  <input
                    type="text"
                    name="aboutUs"
                    placeholder="How did you hear about us?"
                    value={formData.aboutUs}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="field">
                <div className="input-bordered">
                  <input
                    type="text"
                    name="gender"
                    placeholder="Gender"
                    value={formData.gender}
                    onChange={handleChange}
                  />
                </div>
              </div>
             

              <div className="field">
                <div className="input-bordered">
                  <label htmlFor="file">Upload your Citizenship ID or DOB or Certificates!</label>
                  <br />
                  <br />
                  <br />
                  <input
                    type="file"
                    name="file"
                    onChange={handleFileChange}
                    className="i-u"
                  />
                </div>
              </div>
              <br />
              <br />

              <div className="field">
                <input type="submit" value="Submit" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footers />
    </div>
  );
};

export default Newadmission;

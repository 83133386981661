import React, { useState } from "react";
import "./poplogin.css"; // Create a CSS file for styling the popup

const PLogin = ({ setShowLoginPopup }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    console.log("Logging in with:", email, password);
    setShowLoginPopup(false); // Close the popup after login
  };

  const googleAuth = () => {
    window.open("http://localhost:8080/auth/google/callback", "_self");
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-image">
          <img
            src="https://www.shutterstock.com/image-vector/illustration-batsman-playing-cricket-action-600nw-2029010771.jpg"
            alt="Login"
            className="login-image"
          />
        </div>
        <div className="login-form">
          <h2>Login</h2>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button onClick={handleLogin}>Login</button>

          {/* Google Login Icon */}
          <button className="google-login"  onClick={googleAuth}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              id="google"
              height="16"
              fill="currentColor"
              class="bi bi-google"
              viewBox="0 0 16 16"
            >
              <path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z" />
            </svg>
            Login with Google
          </button>

          <button onClick={() => setShowLoginPopup(false)}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default PLogin;

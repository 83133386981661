import React, { useEffect, useRef } from "react";
import "./chooses.css";
import gsap from "gsap";
import { Helmet } from "react-helmet";
const AdvertiserServices = () => {
  const sliderref = useRef(null);

  useEffect(() => {
    const sections = document.querySelectorAll(".projects__card");

    sections.forEach((section) => {
      const whyChooseUs = section.querySelector(".why-choose-us");

      gsap.fromTo(
        whyChooseUs,
        { x: -100, opacity: 0 },
        {
          x: 0,
          opacity: 1,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: "top 80%", // Adjust the start point as needed
            end: "bottom 20%", // Adjust the end point as needed
            scrub: true,
          },
        }
      );
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Why Choose Us - Royal Cricket Academy</title>
        <meta
          name="description"
          content="Explore the top reasons to choose Royal Cricket Academy, including expert coaching, holistic training programs, special events, and state-of-the-art facilities. Discover why we're the leading cricket academy."
        />
        <meta
          name="keywords"
          content="Royal Cricket Academy, expert coaching, holistic cricket training, special cricket events, turf wickets, regular tournaments, diverse game selection"
        />
        <meta
          property="og:title"
          content="Why Choose Us - Royal Cricket Academy"
        />
        <meta
          property="og:description"
          content="Discover why Royal Cricket Academy stands out with expert coaching, comprehensive training programs, special events, and top-notch facilities. Join us to enhance your cricket skills in a supportive environment."
        />
        <meta
          property="og:image"
          content="https://i0.wp.com/avenuemail.in/wp-content/uploads/2023/01/tata-motors.jpg?resize=1024%2C682&ssl=1"
        />
        <meta
          property="og:url"
          content="https://www.royalcricketacademy.com/why-choose-us"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <section className="projects section" id="projects" ref={sliderref}>
        <h2 className="section__title-1">
          <h1 id="why-choose-us">Why Choose Us</h1>
        </h2>

        <ul className="cards">
          <li className="cards__item">
            <div className="card">
              <div
                className="card__image card__image--fence"
                alt="Expert Coaching Staff"
              ></div>
              <div className="card__content">
                <div className="card__title">Expert Coaching Staff</div>
                <p className="card__text">
                  Our coaches are seasoned professionals with extensive
                  experience in both playing and coaching cricket at high
                  levels. Their expertise ensures top-notch training for every
                  player.
                </p>
              </div>
            </div>
          </li>
          <li className="cards__item">
            <div className="card">
              <div
                className="card__image card__image--river"
                alt="Holistic Training Programs"
              ></div>
              <div className="card__content">
                <div className="card__title">Holistic Training Programs</div>
                <p className="card__text">
                  Our programs cover all facets of cricket, including batting,
                  bowling, and fielding, as well as fitness, nutrition, and
                  mental conditioning. Perfect for players of all levels.
                </p>
              </div>
            </div>
          </li>
          <li className="cards__item">
            <div className="card">
              <div
                className="card__image card__image--record"
                alt="World-Class Training Facilities"
              ></div>
              <div className="card__content">
                <div className="card__title">
                  World-Class Training Facilities
                </div>
                <p className="card__text">
                  We boast top-tier training facilities equipped with the latest
                  technology, including advanced bowling machines and
                  high-quality practice pitches, to enhance your cricket skills.
                </p>
              </div>
            </div>
          </li>
          <li className="cards__item">
            <div className="card">
              <div
                className="card__image card__image--flowers"
                alt="Turf Wicket"
              ></div>
              <div className="card__content">
                <div className="card__title">Turf Wicket</div>
                <p className="card__text">
                  A turf wicket offers a superior playing surface that is ideal
                  for both practice and competitive matches. It provides a more
                  authentic and challenging cricket experience.
                </p>
              </div>
            </div>
          </li>
          <li className="cards__item">
            <div className="card">
              <div
                className="card__image card__image--npl"
                alt="Regular Tournaments and Games"
              ></div>
              <div className="card__content">
                <div className="card__title">Regular Tournaments and Games</div>
                <p className="card__text">
                  Participate in our exciting regular tournaments and games,
                  offering a competitive yet friendly environment for players of
                  all skill levels to showcase their talents.
                </p>
              </div>
            </div>
          </li>
          <li className="cards__item">
            <div className="card">
              <div
                className="card__image card__image--chandan"
                alt="Diverse Game Selection"
              ></div>
              <div className="card__content">
                <div className="card__title">Diverse Game Selection</div>
                <p className="card__text">
                  Hosting U16 and U19 selection events at our ground gives young
                  players a unique chance to demonstrate their skills in a
                  familiar and supportive environment.
                </p>
              </div>
            </div>
          </li>
        </ul>
      </section>
    </>
  );
};

export default AdvertiserServices;
